<template>
    <div class="referrer">
        <!--导航-->
        <van-nav-bar :title="navTitle"  left-arrow @click-left="leftReturn" fixed/>
        <div class="communityDiv">
            <!--搜索栏-->
            <div class="searchDiv">
                <van-search  placeholder="请输入搜索关键词" v-model="goodClassName" size="large" @search="toSearch()"/>
            </div>
        </div>
        <div class="likeShowDiv">
            <ul >
                <li  @click="selectOption(item)"  class="likeShowLi" v-for="item,index in decorationList"  :label="item.itemName + '-' + item.price" :key="index">
                    
                    <div class="staff-info">
                        <div class="staff-name">{{item.middlesumName}}-{{item.itemName}}</div>
               
                        <div class="staff-name" style="color: #999;font-weight:normal;">{{item.price+'元'}}</div>
                                                 <div class="staff-name" v-if="item.supplierName" style="float:right;color: #999;font-weight:normal;margin-top:-30px">{{item.supplierName}}</div> 
                         
                        
                    </div>
                </li>
            </ul>
        </div>
    </div>    
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
        Row,
        Col,
        search
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
        querGoodsallocation,querGoodsallocationCount
	} from "../../getData/getData";
	import {
		responseUtil
	} from "../../libs/rongxunUtil";
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
    import eventBus from "../../components/rongxun/rx-navBar/envbus.js"
	export default {
		name: "decorat",
		data() {
			return {
                profilePhoto:require('../../assets/images/default-headImg.jpg'),
                navTitle:'装修项',
                referrerText:'',
                decorationList:[],
                goodClassName:''   //搜索词
			}
		},
		components: {
			[NavBar.name]: NavBar,
            [search .name]:search,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
            [Row.name]: Row,
            [Col.name]: Col,
			dialMobile,
			rxNavBar

		},
		created() {
		},
		mounted() {
            if(this.$route.query.type=='decorateList'){
                this.getGoodsList()  
            }else if(this.$route.query.type=='changeApplication'){
                this.getGoodsListNew()
            }
            
		},
		methods: {
            toSearch(){
            if(this.$route.query.type=='decorateList'){
                this.getGoodsList()  
            }else if(this.$route.query.type=='changeApplication'){
                this.getGoodsListNew()
            }
            },
            selectOption(item){
                item.areaName = this.$route.query.areaName
                item.areaId = this.$route.query.areaId
                eventBus.$emit('decorationChoice',item);
                this.leftReturn()
            },
            leftReturn(){
                this.$router.go(-1);
            },
            //获取小项列表
            getGoodsList(){
                let that=this
                let initData={}
                initData.goodClass_id=that.$route.query.id
                initData.sethouse_id=that.$route.query.sethouse_id
                initData.itemType=that.$route.query.decorationType
                initData.goodClassName=that.$route.query.goodClassName?that.$route.query.goodClassName:that.goodClassName
                initData.afterRent_id=that.$route.query.arid
                initData.roomHouse_id=that.$route.query.roomHouse_id
                querGoodsallocation(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {      
                        that.decorationList=response.data.data.data
                        //console.log(that.decorationList)
                  })
                })                
            },
            //获取小项列表
            getGoodsListNew(){
                let that=this
                let initData={}
                initData.goodClass_id=that.$route.query.id
                initData.sethouse_id=that.$route.query.sethouse_id
                initData.itemType=that.$route.query.decorationType
                initData.goodClassName=that.$route.query.goodClassName?that.$route.query.goodClassName:that.goodClassName
                initData.afterRent_id=that.$route.query.arid
                initData.roomHouse_id=that.$route.query.roomHouse_id
                querGoodsallocationCount(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {      
                        that.decorationList=response.data.data.data
                        //console.log(that.decorationList)
                  })
                })                
            },							
		},
        beforeDestroy() {
          eventBus.$off('decorationChoice')
        }
	}
</script>

<style scoped lang="less">
.likeShowLi{
   height:1.9rem;
   margin-bottom:10px;
   padding-bottom:10px;
}
.staff-name{
    margin:10px;
}
.referrer .staff-info{
    width:90%;
}
</style>
